<script setup>
import { ref, watch } from "vue";
import c3api from "../../c3api";
import { onMounted } from "vue";
import { useUserStore } from "../../stores/user";
import { useToast } from "vue-toastification";
import { setPageTitle } from "../../utils/pageTitle";
import { useRouter, useRoute, definePage } from "vue-router/auto";

definePage({
  meta: {
    requiresAuth: false,
  },
});

const password = ref(null);
const passwordConfirmation = ref(null);
const userStore = useUserStore();
const router = useRouter();
const route = useRoute();
const loading = ref(false);
const toast = useToast();
setPageTitle("Confirm Password Reset");

const submitForm = async () => {
  loading.value = true;
  c3api
    .patch(`/auth/reset_password/${route.params.token}`, {
      password: password.value,
      password_confirmation: passwordConfirmation.value,
    })
    .then(async (response) => {
      toast.success("Success! Your password has been reset. You can now login.");
      router.push("/");
    })
    .catch((error) => {
      console.error(error);
      toast.error(error.response.data.data.join(". "));
    })
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  if (localStorage.getItem("token")) {
    userStore.fetchUser();
  }
});
</script>

<template>
  <v-container
    class="d-flex flex-wrap justify-center align-center"
    style="height: 35vh; max-width: 540px"
  >
    <div class="d-flex flex-column justify-center align-center" v-if="loading">
      <v-progress-circular
        :width="10"
        :size="100"
        color="orange"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card v-if="!userStore.user && !loading" class="w-100 pa-2">
      <v-card-item>
        <v-card-title class="pb-1">Set New Password</v-card-title>
      </v-card-item>
      <v-card-text class="mt-4">
        <div>
          <v-text-field type="password" label="New Password" v-model="password" />
        </div>
        <div>
          <v-text-field
            type="password"
            label="Confirm Password"
            v-model="passwordConfirmation"
          />
        </div>
        <div class="d-flex justify-end mt-2">
          <v-btn @click.prevent="submitForm" type="submit" color="primary">Update</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>
../stores/error
